let origin = "https://future.zhuneng.cn";
//积分
const pointsUrl = `/accApi/accPoints/pointuser/getPointUserDetail`;
//获取商品列表
const getGoodsListUrl = `/placeApi/front/pointsMall/list`;
//获取商品详情
const getGoodsDetailUrl = `/placeApi/front/pointsMall/goodsDetailById`;
//积分兑换商品
const getChangeUrl = `/placeApi/front/pointsMall/exchangeGoods`;
//订单详情页
const getOrderDetailUrl = `/placeApi/front/pointsMall/userPointExchangeDetail`;
//积分订单列表
const getOrderListUrl = `/placeApi/front/pointsMall/userPointExchangeList`;
//核销
const handleOrderUrl = `/placeApi/front/pointsMall/sceneOrderCompleted`;
export {
  getChangeUrl,
  getGoodsListUrl,
  getGoodsDetailUrl,
  pointsUrl,
  getOrderDetailUrl,
  getOrderListUrl,
  handleOrderUrl,
};
