<template>
  <div class="mall">
    <div class="mall-t">
      <div class="mall-tc">
        <div class="totalPoints">
          <div class="item-t">
            <count-to
              :start-val="0"
              :end-val="pointsData.totlePointCount"
              :duration="1000"
              class="card-panel-num"
            />
          </div>
          <div class="item-b">总积分</div>
        </div>
        <div class="usePoints">
          <div class="item-t">
            <count-to
              :start-val="0"
              :end-val="pointsData.availablePoint"
              :duration="1000"
              class="card-panel-num"
            />
          </div>
          <div class="item-b">可用积分</div>
        </div>
      </div>
    </div>
    <div class="mall-c">
      <div class="mallIcon">
        <div class="item" @click="toList">
          <div class="item-t"><img src="@/assets/img/points.png" alt="" /></div>
          <div class="item-b">积分明细</div>
        </div>
        <div class="item" @click="toRank">
          <div class="item-t"><img src="@/assets/img/ranks.png" alt="" /></div>
          <div class="item-b">积分排行</div>
        </div>
        <div class="item" @click="toIncrease">
          <div class="item-t">
            <img src="@/assets/img/increase.png" alt="" />
          </div>
          <div class="item-b">积分转赠</div>
        </div>
        <!-- <div class="item" @click="toIncome">
          <div class="item-t">
            <img src="@/assets/img/myorder.png" alt="" />
          </div>
          <div class="item-b">积分收入</div>
        </div> -->
        <div class="item" @click="toOrder">
          <div class="item-t">
            <img src="@/assets/img/myorder.png" alt="" />
          </div>
          <div class="item-b">我的订单</div>
        </div>
      </div>
    </div>
    <div class="mall-title">
      <span class="title">积分商城</span>
      <span class="more" @click="toRule">积分获取规则</span>
    </div>
    <div class="mall-b">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div class="mallList">
          <div
            class="item"
            v-for="(item, index) in listData"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="item-t"><img :src="item.imageUrl" alt="" /></div>
            <div class="item-b">
              <div class="title">{{ item.title }}</div>
              <div class="price">{{ item.pointsPrice }}积分</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
    <div class="toHome" v-if="fromGetpoint == 1" @click="toHome">
      <img src="./img/home.png" alt="" />
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import CountTo from "vue-count-to";
import { getGoodsListUrl, pointsUrl } from "./api.js";
import { getHashParam, handleImg } from "@/utils/utils.js";
import { gloabalCount } from "@/utils/common.js";
var moment = require("moment");
export default {
  name: "points",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      pointsData: {
        totlePointCount: 0,
        availablePoint: 0,
      },
      listData: [],
      fromGetpoint: "",
    };
  },
  components: { CountTo },
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.fromGetpoint = this.$route.query.fromGetpoint;
  },
  mounted() {
    if (this.userId) {
      this.getPoints();
    }
    gloabalCount("", 17, 1);
  },
  methods: {
    toHome() {
      wx.miniProgram.redirectTo({
        url: `/pages/index/index`,
      });
    },
    toOrder() {
      if (this.userId && this.userId != 0) {
        this.$router.push({
          name: "mallOrderList",
        });
      } else {
        let toUrl = this.$route.path;
        let params = this.$route.query;
        wx.miniProgram.redirectTo({
          url: `/pages/register/index?isRegister=2&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
            params
          )}`,
        });
      }
    },
    toList() {
      if (this.userId && this.userId != 0) {
        this.$router.push({
          name: "pointsList",
        });
      } else {
        let toUrl = this.$route.path;
        let params = this.$route.query;
        wx.miniProgram.redirectTo({
          url: `/pages/register/index?isRegister=2&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
            params
          )}`,
        });
      }
    },
    toIncrease() {
      if (this.userId && this.userId != 0) {
        this.$router.push({
          name: "pointIncrease",
        });
      } else {
        let toUrl = this.$route.path;
        let params = this.$route.query;
        wx.miniProgram.redirectTo({
          url: `/pages/register/index?isRegister=2&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
            params
          )}`,
        });
      }
    },
    toIncome() {
      // if (this.userId && this.userId != 0) {
      //   console.log("积分收入页面");
      //   // this.$router.push({
      //   //   name: "pointRankList",
      //   // });
      // } else {
      //   console.log("积分收入页面");
      //   let toUrl = this.$route.path;
      //   let params = this.$route.query;
      //   wx.miniProgram.redirectTo({
      //     url: `/pages/register/index?isRegister=2&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
      //       params
      //     )}`,
      //   });
      // }
    },
    toRank() {
      if (this.userId && this.userId != 0) {
        this.$router.push({
          name: "pointRankList",
          query: { id: "1" },
        });
      } else {
        let toUrl = this.$route.path;
        let params = this.$route.query;
        wx.miniProgram.redirectTo({
          url: `/pages/register/index?isRegister=2&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
            params
          )}`,
        });
      }
    },
    toRule() {
      this.$router.push({
        name: "pointsRule",
      });
    },
    getPoints() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios
        .post(`${pointsUrl}`, this.$qs.stringify(params), {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data) {
              this.pointsData = res.data;
            }
          } else {
          }
        });
    },
    toDetail(item) {
      this.$router.push({
        name: "mallDetail",
        query: { id: item.id },
      });
    },
    onLoad() {
      this.getGoodsList();
    },
    getGoodsList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        sendPointFlag: 1, // 传1 表示调该接口时后端会自行调获取组合任务积分的接口 不传此参数则不掉
        // userId: this.userId,
        // tenantId: this.tenantId,
        // roomId: this.roomId,
        // communityId: this.communityId,
      };
      if (this.userId) {
        params.userId = this.userId;
      }
      this.$axios.get(`${getGoodsListUrl}`, { params: params }).then((res) => {
        console.log(res, 333);
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.imageUrl) {
              item.imageUrl = handleImg(300, 300, item.imageUrl);
            }
            this.listData.push(item);
          });
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.mall {
  min-height: 100vh;
  position: relative;
  .mall-t {
    height: 328px;
    padding: 0 32px;
    background: url("./img/topBack.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 52px;
    box-sizing: border-box;
    .mall-tc {
      display: flex;
      align-items: center;
      text-align: center;
      color: #fff;
      justify-content: space-around;
      .totalPoints,
      .usePoints {
        height: 140px;
        .item-t {
          font-size: 60px;
          font-weight: 700;
          margin-bottom: 10px;
        }
        .item-b {
          font-size: 28px;
        }
      }
      .btnPoints {
        width: 200px;
        height: 64px;
        background: #fff;
        font-size: 28px;
        line-height: 64px;
        color: rgba(0, 126, 255, 1);
        border-radius: 30px;
      }
    }
  }
  .mall-c {
    padding: 0 30px;
    margin: -96px 0 0;
    .mallIcon {
      background: #fff;
      box-sizing: border-box;
      padding: 30px;
      border-radius: 20px;
      box-shadow: 0px 0px 40px 12px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-around;

      .item-t {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-b {
        text-align: center;
        font-size: 28px;
        color: #666666;
      }
    }
  }
  .mall-title {
    padding: 0 40px 32px;
    margin: 64px 0 0;
    display: flex;
    span {
      line-height: 48px;
      &.title {
        flex: 1;
        font-size: 36px;
      }
      &.more {
        font-size: 28px;
        color: #007eff;
      }
    }
  }

  .mallList {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 32px 0;
    justify-content: space-between;
    .item {
      // background-color: #fff;
      margin-top: 20px;
      border-radius: 16px;
      overflow: hidden;
      &:nth-of-type(1),
      &:nth-of-type(2) {
        padding-top: 0;
        margin-top: 0;
      }
      box-sizing: border-box;
      .item-t {
        width: 328px;
        height: 240px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-b {
        width: 310px;
        margin-top: 20px;
        font-size: 30px;
        line-height: 36px;
        padding-left: 10px;
        margin-bottom: 32px;
        .title {
          margin: 10px 0;
          width: 100%;
          // display: -webkit-box;
          // -webkit-box-orient: vertical;
          // -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 32px;
          font-weight: 500;
        }
        .price {
          margin-top: 10px;
          color: #fe6f16;
          font-size: 32px;
          font-weight: 500;
        }
      }
    }
  }
  .toHome {
    width: 120px;
    height: 120px;
    background: #fff;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 10%);
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    img {
      width: 80%;
      height: 80%;
      vertical-align: middle;
    }
    &::after {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
  }
}
</style>
<style lang="less">
.mall {
}
</style>
